<template>
  <div class="faq-page">
      <div class="page-header">
        <h3>How can we help you?</h3>
        <p>A quick way to know about LMS</p>
      </div>
    <section class="general-faq">
      <div class="container general-faq-cntnr">
        <div class="faq-generic-title">General</div>
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">What is KIPS LMS?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              Continuing the legacy of being a pioneer, KIPS has introduced a
              comprehensive online Learning Management System (KIPS LMS) for the
              registered students of KIPS Entry Tests Preparations. KIPS LMS
              facilitates students by providing them with online learning in the
              form of video lectures, readings, tests, and teacher support.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              Which browser is recommended for using KIPS LMS?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              The updated version of Google Chrome is recommended for KIPS LMS.
              However, Microsoft Edge, Mozilla FireFox and Safari also support
              KIPS LMS.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
             Which mobile OS version is recommended for using KIPS LMS App?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
            Android devices need to have 6.0 or above and IOS devices need to have 11.0 or
             above for a hassle-free experience.
            </div>
          </div>
        </div>
        <div class="row faq-row">
        <div class="col-md-4">
            <div class="faq-section-heading">
              Are there any specific requirements of Internet Connection for
              KIPS LMS?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              High speed uninterupted internet connection is required for
              attempting tests, watching videos and readings, and using all
              other LMS features without any hindrance. (3G connection or above
              is recommended for mobile users).
            </div>
          </div>
        </div>
          
      </div>
    </section>

    <section class="registration-faq">
      <div class="container registration-faq-cntnr">
        <div class="faq-generic-title">Registration</div>
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">
              How do I get registered for KIPS LMS Account?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              To get an LMS account, you need to get yourself registered at a
              KIPS Campus. Please note that all sessions are not available on
              KIPS LMS. For details, please contact the relevant Campus Manager.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              How do I get Username and Password for KIPS LMS Account?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              If your session is available on KIPS LMS, your username and
              password will be sent within 48 hours to the contact number you provided when
              getting registered.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              What do I do if I forget my LMS account password?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              In case you forget your password, click the "Forgot Password" link
              on the login page. After entering your username, your new password
              will be sent to your registered contact number.
            </div>
          </div>
        </div>

        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">
              How do I recover my LMS account in case I forget my username?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              Send an Email to support@kipslms.com with your registration slip
              attached. Make sure all the details in the registration slip are
              clearly visible. Your credentials (username and password) will be
              sent to your registered contact number.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              How long does my LMS Account remain valid?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              KIPS LMS sessions are synchronized with KIPS Campus sessions. Your
              LMS account will expire when your campus session is completed.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="lms-session-faq">
      <div class="container lms-session-faq-cntnr">
        <div class="faq-generic-title">LMS Sessions/Programs</div>
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">
              Which sessions are available on LMS?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              Contact your Campus manager for information regarding the
              availability of a session. Not all sessions conducted in campuses
              are available on LMS.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              What do I do if I get registered to the wrong program? How do I
              change the registered program?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              You need to provide all the details to the campus manager, after
              whose approval, your program will be changed accordingly.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="howto-faq">
      <div class="container howto-faq-cntnr">
        <div class="faq-generic-title">How To's</div>
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">
              How do I attempt tests, watch videos, and view readings?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              When you click on a subject's tab on "My Programs" page, you will
              find week-wise readings, videos and tests. Alternatively, you can
              search for your desired videos and readings from the Koogle page.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              How can I access the locked contents?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              Your session is synchronized with the Campus. You can view the
              weekly SOS and its daily contents (both unlocked and locked) but
              can access only the unlocked contents. The contents are unlocked
              day by day as per the SOS of your session.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              How do I report a mistake I find in a question, its answer, or in
              readings and videos etc.?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              You may share the details of the mistake through teacher support,
              or through email to the support team. You can also contact your
              Campus Manager or Class Teacher for this purpose.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="support-faq">
      <div class="container support-faq-cntnr">
        <div class="faq-generic-title">Support</div>
        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">What is teacher support?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              This feature of KIPS LMS enables you to chat with subject experts
              to find instant answers to your queries.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              How do I access teacher support?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              You can access chat from the Subject SOS page or through the
              Teacher Support link in the left menu.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              At what times is teacher support available?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              Teacher Support is available on working days between 10:00 am -
              8:00 pm.
            </div>
          </div>
        </div>

        <div class="row faq-row">
          <div class="col-md-4">
            <div class="faq-section-heading">How do I contact LMS support?</div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              Send an email to support@kipslms.com. Your queries related to the
              LMS will be catered within 48 hours.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              How can I view my progress in my programs?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              You will find a 'Stats' link in the left menu. From there, you can
              view a graphical representation of the extent to which you have
              completed the course of each subject in each of your programs,
              along with your result in each test.
            </div>
          </div>

          <div class="col-md-4">
            <div class="faq-section-heading">
              How can I know where I stand in my current session in comparison
              to other LMS users?
            </div>
            <div class="green-border-line"></div>
            <div class="faq-section-paragraph">
              You will find a 'Leaderboard' link in the left menu. From there,
              you can view the top 20 positions of your program among all LMS
              users of the current academic year.
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <app-footer></app-footer> -->
    
   
  </div>
</template>

<script>
import Footer from "../layout/landing/appFooter.vue";

export default {
  components: {
    appFooter: Footer,
  },
  metaInfo: {
    title: "FAQ | KIPS LMS",
    // meta: [
    //   {
    //     name: "description",
    //     content:
    //       "Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.",
    //   },
    //   {
    //     property: "og:title",
    //     content: "Epiloge - Build your network in your field of interest",
    //   },
    // ],
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },
  computed: {},
  asyncData({ store, route }) {},
  created() {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {}
};
</script>
